<template>
  <div class="comp-bottom">
    <div class="bottom-btn-list">
      <img src="../../assets/images/bottom-left.png" class="btn-bar-left"/>

      <div class="btn-list">
        <span class="bottom-btn" :class="{'active': btnType == 1}" @click="openModalList(4, 1, '荣誉品牌')">荣誉品牌</span>
        <span class="bottom-btn" :class="{'active': btnType == 2}" @click="openModalList(5, 2, '公司动态')">公司动态</span>
        <span class="bottom-btn" :class="{'active': btnType == 3}" @click="openModalList(6, 3, '抗衰科普')">抗衰科普</span>
        <span class="bottom-btn" :class="{'active': btnType == 4}" @click="openModalList(7, 4, '联系我们')">联系我们</span>
        <span class="bottom-btn" :class="{'active': btnType == 5}" @click="openModalList(8, 5, '投资者关系')">投资者关系</span>
        <span class="bottom-btn" :class="{'active': btnType == 6}" @click="openModalList(9, 6, '客户反馈')">客户反馈</span>
      </div>
      <img src="../../assets/images/bottom-right.png" class="btn-bar-left"/>
    </div>
    <div class="footer-text">Copyright © 2023-2028  浙ICP备2024070872号</div>

    <div class="spe-modal " v-if="showModalList">
      <img class="spe-modal-close" src="../../assets/images/modal-close.png" @click="showModalList = false" alt="">
      <div class="spe-modal-title">{{ listTitle || '图文列表' }}</div>
      <div class="spe-modal-main">
        <div class="modal-list">
          <div class="modal-list-item" @click="openModalEmpty(item.id)" v-for="(item, index) in newsList" :key="index">
            <span class="label">{{ item.newsname }}</span>
            <Icon type="ios-arrow-forward" class="icon-right"/>
          </div>
        </div>

        <div class="modal-list-notice">
          <div>下滑查看更多</div>
          <Icon type="ios-arrow-down"/>
        </div>
      </div>
    </div>

    <div class="spe-modal spe-empty-bg" v-if="showModalEmpty">
      <img class="spe-modal-close" src="../../assets/images/modal-close.png" @click="showModalEmpty = false" alt="">
      <div class="spe-modal-title">{{ newsname }}</div>
      <div class="spe-modal-main">
        <div class="modal-empty" v-html="news">
          <!--            {{ news }}-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import * as echarts from 'echarts'; // TODO 按模块引用

export default {
  name: '',
  components: {},
  data() {
    return {
      value: 0,
      value1: 0,
      btnType: 1,

      // 留言框
      showMsgBox: false,

      //列表弹框
      showModalList: false,

      // 空白弹框
      showModalEmpty: false,

      // 图片预览
      showModalPreview: false,
      cate1: '',
      cate2: '',
      cate3: '',
      cate4: '',
      cate5: '',
      cate6: '',
      cate7: '',
      cate8: '',
      cate9: '',
      cate10: '',
      cate11: '',
      newsname: '',
      news: '',
      newsList: [],
      msg: '',
      bannerList: [],
      oxygenList: [],

      listTitle:'',
    }
  },

  mounted() {

  },
  methods: {
    goPage() {
      this.$router.push('/ImgDetail')
    },
    openModalEmpty(id, type) {
      this.btnType = type
      this.showModalEmpty = true
      let params = {
        id: id,
      }
      this.AXIOS.get('/index/getNewsDetail', params).then(res => {
        this.newsname = res.newsname
        this.news = res.content
      }).finally(res => {
      })
    },
    openModalList(newscategoryid, type, title) {
      this.listTitle= title
      this.btnType = type
      this.showModalList = true
      this.getNewsList(newscategoryid)
    },
    getNewsList(newscategoryid) {
      let params = {
        page: 1,
        pageSize: 10,
        newscategoryid: newscategoryid
      }
      this.AXIOS.get('/index/getNews', params).then(res => {
        this.newsList = res.data
      }).finally(res => {
      })
    },
  }
}
</script>
<style lang="less">
.comp-bottom {
  min-height: 100%;
  color: #fff;
  padding-bottom: 40px;

  .spe-modal {
    display: inline-block;
    width: 850px;
    z-index: 111;
    height: 866px;
    background: url("../../assets/images/modal-bg.png");
    background-size: 850px 866px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    .icon-close {
      font-size: 40px;
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
    }

    &.spe-empty-bg {
      width: 1020px;
      z-index: 111;
      height: 866px;
      background: url("../../assets/images/modal-bg-border.png");
      background-size: 1020px 866px;
      padding: 20px 32px;
      .spe-modal-close {
        display: inline-block;
        position: absolute;
        top: 5px;
        right: 5px;
        width: 87px;
        height: 91px;
        cursor: pointer;
      }

      .spe-modal-main {
        box-sizing: border-box;
        height: 700px;
        margin-top: 10px;
      }
    }

    .spe-modal-title {
      font-size: 28px;
      height: 60px;
      padding-left: 10px;
      padding-top: 10px;
      box-sizing: border-box;
    }

    .spe-modal-close {
      display: inline-block;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 87px;
      height: 91px;
      cursor: pointer;
    }

    .spe-modal-main {
      margin-top: 30px;
      box-sizing: border-box;
      height: 590px;
    }

    .modal-empty {
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      height: 730px;
      padding: 20px;
      overflow-y: scroll;
      background: #fff;
      color: #333;
      font-size: 16px;
    }

    .modal-list {
      box-sizing: border-box;
      height: 630px;
      overflow-y: scroll;
      overflow-x: hidden;

      .modal-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        margin-bottom: 15px;
        box-sizing: border-box;
        background: #014761;
        font-size: 20px;
        cursor: pointer;

        &:hover {
          background: darken(#014761, 3%);
        }

        .icon-right {
          font-size: 30px;
        }
      }

    }


    .modal-list-notice {
      text-align: center;
      width: 100%;
      left: 0;
      position: absolute;
      bottom: 0;
      padding: 15px 20px;
      box-sizing: border-box;
      font-size: 14px;
      cursor: pointer;

      .icon-right {
        font-size: 30px;
      }
    }

  }


  .bottom-btn-list {
    margin-top: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-bar-left {
      width: 220px;
    }

    .btn-list {

      .bottom-btn {
        width: 120px;
        height: 37px;
        line-height: 37px;
        border-radius: 5px;
        display: inline-block;
        cursor: pointer;
        background: url("../../assets/images/btn-bg.png") 100% 100%;
        text-align: center;
        font-size: 16px;
        margin: 0 10px;

        &:hover {
          background: url("../../assets/images/btn-bg-active.png") 100% 100%;
        }
      }
    }

    .btn-bar-right {
      width: 220px;
    }
  }

  .footer-text {
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
