<template>
  <div class="index-page">
    <div class="page-bg-left"></div>
    <div class="page-bg-right"></div>
    <div class="main-container">
      <div class="page-header">
        <img src="../assets/images/logo.png" class="logo" alt="">
        <div class="top-carousel">
          <Carousel :autoplay-speed="3000" autoplay v-model="value" loop>
            <CarouselItem v-for="(item,index) in bannerList" :key="index">
              <img class="img-carousel" :src="item.pc_image" alt="">
            </CarouselItem>
            <div v-if="bannerList.length === 0">暂无图片</div>
          </Carousel>
        </div>
      </div>

      <div class="page-main">
        <div class="panel-round">
          <img class="img-round round-1" src="../assets/images/round.png" alt="">
          <img class="img-round round-2" src="../assets/images/round.png" alt="">
          <img class="img-round round-3" src="../assets/images/round.png" alt="">
        </div>

        <div class="page-left">
          <div class="panel-title">高压氧抗衰舱</div>
          <div class="left-main">
            <Carousel :autoplay-speed="3000" autoplay v-model="value1" loop >
              <CarouselItem  v-for="(item,index) in oxygenList" :key="index">
                <img @click="goPage" class="img-carousel" :src="item.pc_image" alt="">
              </CarouselItem>
            </Carousel>
          </div>
        </div>
        <div class="page-center">
          <div class="panel-title" @click="openModalEmpty(cate13.id || '')">六核抗衰法</div>

          <div class="center-main">
            <div class="square-center">
              <div class="center-text">六核抗衰法</div>
              <span class="btn-action" @click="openModalEmpty(cate13.id || '')">点击了解详情</span>
            </div>

            <div class="square-btn square-1">
              <span class="btn-text" :class="{'more-text': cate1.newsname&& cate1.newsname.length > 6}">{{ cate1.newsname }}</span>
              <span class="btn-action" @click="openModalEmpty(cate1.id)">点击了解详情</span>
            </div>

            <div class="square-btn square-2">
              <span class="btn-text " :class="{'more-text':cate2.newsname&& cate2.newsname.length > 6}">
                {{ cate2.newsname }}
              </span>
              <span class="btn-action" @click="openModalEmpty(cate2.id)">点击了解详情</span>
            </div>

            <div class="square-btn square-3">
              <span class="btn-text" :class="{'more-text':cate3.newsname&& cate3.newsname.length > 6}">{{ cate3.newsname }}</span>
              <span class="btn-action" @click="openModalEmpty(cate3.id)">点击了解详情</span>
            </div>
            <div class="square-btn square-4">
              <span class="btn-text" :class="{'more-text':cate4.newsname && cate4.newsname.length > 6}">{{ cate4.newsname }}</span>
              <span class="btn-action" @click="openModalEmpty(cate4.id)">点击了解详情</span>
            </div>
            <div class="square-btn square-5">
              <span class="btn-text" :class="{'more-text':cate5.newsname && cate5.newsname.length > 6}">{{ cate5.newsname }}</span>
              <span class="btn-action" @click="openModalEmpty(cate5.id)">点击了解详情</span>
            </div>
            <div class="square-btn square-6">
              <span class="btn-text" :class="{'more-text': cate6.newsname && cate6.newsname.length > 6}">
                {{ cate6.newsname }}
              </span>
              <span class="btn-action" @click="openModalEmpty(cate6.id)">点击了解详情</span>
            </div>
          </div>
        </div>
        <div class="page-right">
          <div class="panel-title">衰老检测</div>
          <div class="right-main">
            <div class="right-list">
              <div class="right-list-item" @click="openModalEmpty(cate7.id)">{{ cate7.newsname }}</div>
              <div class="right-list-item" @click="openModalEmpty(cate8.id)">{{ cate8.newsname }}</div>
              <div class="right-list-item" @click="openModalEmpty(cate9.id)">{{ cate9.newsname }}</div>
              <div class="right-list-item" @click="openModalEmpty(cate10.id)">{{ cate10.newsname }}</div>
              <div class="right-list-item" @click="openModalEmpty(cate11.id)">{{ cate11.newsname }}</div>
            </div>
          </div>
        </div>
      </div>

      <BottomBar></BottomBar>

<!--      <div class="float-btn float-btn-1">-->
<!--        <Icon type="md-chatbubbles"/>-->
<!--        <div>联系客服</div>-->
<!--      </div>-->
      <div class="float-btn float-btn-2" @click="openMsgBox">
        <Icon type="md-mail"/>
        <div>留言板</div>
      </div>


      <div class="spe-modal" v-if="showModalList">
        <img class="spe-modal-close" src="../assets/images/modal-close.png" @click="showModalList = false" alt="">
        <div class="spe-modal-title">图文列表</div>
        <div class="spe-modal-main">
          <div class="modal-list">
            <div class="modal-list-item" @click="openModalEmpty" v-for="(item, index) in newsList" :key="index">
              <span class="label">{{ item.newsname }}</span>
              <Icon type="ios-arrow-forward" class="icon-right"/>
            </div>
          </div>

          <div class="modal-list-notice">
            <div>下滑查看更多</div>
            <Icon type="ios-arrow-down"/>
          </div>
        </div>
      </div>

      <div class="spe-modal spe-empty-bg" v-if="showModalEmpty">
        <img class="spe-modal-close" src="../assets/images/modal-close.png" @click="showModalEmpty = false" alt="">
        <div class="spe-modal-title">{{ newsname }}</div>
        <div class="spe-modal-main">
          <div class="modal-empty" v-html="news">
<!--            {{ news }}-->
          </div>
        </div>
      </div>

      <div class="msg-box" v-if="showMsgBox">
        <div class="msg-box-header">
          <div class="header-left">
            <Icon type="md-mail" class="mail"/>
            <div>留言板</div>
          </div>
          <Icon type="ios-arrow-forward" @click="showMsgBox = false"/>
        </div>
        <div class="msg-box-body">
          <div class="body-notice">
            欢迎光临!  如果您对我们的产品服务感兴趣 或者有任何建议，欢迎随时留言与我们取得联系!
            提交内容的时候，请输入您的联系方式，方便我们与您沟通~
          </div>

          <div class="msg-list">
            <div class="msg-item"
                 v-for="(item, index) in msgList"
                 :key="'msgList' + index">
              {{item}}
            </div>
          </div>

        </div>
        <div class="msg-box-footer">
          <textarea type="text" placeholder="请输入留言" v-model="msg" class="notice-input"/>
          <div class="btn-wrap">
<!--            <div class="send-btn" @click="showMsgBox = false">发送</div>-->
            <div class="send-btn" @click="sendMsg">发送</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
// import * as echarts from 'echarts'; // TODO 按模块引用
import BottomBar from './comp/BottomBar'

export default {
  name: '',
  components: {
    BottomBar
  },
  data() {
    return {
      value: 0,
      value1: 0,
      btnType: 1,

      // 留言框
      showMsgBox: false,

      //列表弹框
      showModalList: false,

      // 空白弹框
      showModalEmpty: false,

      // 图片预览
      showModalPreview: false,
      // imgList: [
      //   {type: 'video', src: 'https://app-1251575231.cos.ap-shanghai.myqcloud.com/pos-callService/download/video.mkv'},
      //   {
      //     type: 'img',
      //     src: 'https://img0.baidu.com/it/u=2777582582,39314593&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=1067'
      //   },
      //   {
      //     type: 'img',
      //     src: 'https://img0.baidu.com/it/u=2777582582,39314593&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=1067'
      //   },
      //   {
      //     type: 'img',
      //     src: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2F0561faa7-b6c5-4001-9d15-0ca3c6467225%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1714318555&t=07c086e43d631917bf9d1dca4bb50fb9'
      //   },
      //   {
      //     type: 'img',
      //     src: 'https://img0.baidu.com/it/u=2777582582,39314593&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=1067'
      //   },
      //   {type: 'img', src: 'https://dss1.bdstatic.com/lvoZeXSm1A5BphGlnYG/skin/44.jpg'},
      //   {type: 'video', src: 'https://app-1251575231.cos.ap-shanghai.myqcloud.com/pos-callService/download/video.mkv'},
      //   {type: 'img', src: 'https://dss1.bdstatic.com/lvoZeXSm1A5BphGlnYG/skin/44.jpg'},
      // ],
      // previewItem: {
      //   type: 'video',
      //   src: 'https://app-1251575231.cos.ap-shanghai.myqcloud.com/pos-callService/download/video.mkv'
      // },
      cate1: {},
      cate2: {},
      cate3: {},
      cate4: {},
      cate5: {},
      cate6: {},
      cate13: {},

      cate7: {},
      cate8: {},
      cate9: {},
      cate10: {},
      cate11: {},
      newsname: '',
      news: '',
      newsList: [],
      msg:'',
      bannerList:[],
      oxygenList:[],


      // 留言框
      msgList: [],
    }
  },

  mounted() {
    this.getCateList1()
    this.getCateList2()
    this.getBannerList()
    this.getImgOrVideo()
  },
  methods: {
    openSlideModal() {
      this.showModalPreview = true
    },
    handleScroll(arrow) {
      let nav = this.$refs.previewBar
      // 定义滚动方向，其实也可以在赋值的时候写
      const moveForwardStep = 1;
      const moveBackStep = -1;
      // 定义滚动距离
      let step = 0;
      // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
      if (arrow == 'right') {
        step = moveForwardStep * 90;
      } else {
        step = moveBackStep * 90;
      }
      // 对需要滚动的元素进行滚动操作
      nav.scrollLeft += step;
    },

    openMsgBox() {
      this.showMsgBox = true
    },
    goPage() {
      this.$router.push('/ImgDetail')
    },
    getImgOrVideo(){
      let params = {
        pageSize: 3,
        'type': 'oxygen'
      }
      this.AXIOS.get('/index/getImgOrVideo', params).then(res => {
        this.oxygenList = res.data
      }).finally(res=>{

      })
    },
    getBannerList(){
      let params = {
        pageSize: 10,
      }
      this.AXIOS.get('/index/getBannerList', params).then(res => {
         this.bannerList = res.data
      }).finally(res => {
      })
    },
    openModalEmpty(id,type) {
      this.btnType = type
      this.showModalEmpty = true
      let params = {
        id: id,
      }
      this.AXIOS.get('/index/getNewsDetail', params).then(res => {
        this.newsname = res.newsname
        this.news = res.content
      }).finally(res => {
      })
    },
    openModalList(newscategoryid,type) {
      this.btnType = type
      this.showModalList = true
      this.getNewsList(newscategoryid)
    },
    getNewsList(newscategoryid){
      let params = {
        page: 1,
        pageSize: 10,
        newscategoryid: newscategoryid
      }
      this.AXIOS.get('/index/getNews', params).then(res => {
        this.newsList = res.data
      }).finally(res => {
      })
    },
    getList() {
      this.AXIOS.get('/monitor/dashboard/getCurrentOnLineHotel', {}).then(res => {
        this.newList = res || []
      }).finally(res => {
      })
    },
    getCateList1(){
      let params = {
        page: 1,
        pageSize: 7,
        newscategoryid: 1
      }
      this.AXIOS.get('/index/getNews', params).then(res => {
          let data = res.data
          console.log(data)
          this.cate1 = data[0]  || {}
          this.cate2 = data[1]  || {}
          this.cate3 = data[2]  || {}
          this.cate4 = data[3]  || {}
          this.cate5 = data[4]  || {}
          this.cate6 = data[5]  || {}
          this.cate13 = data[6] || {}
      }).finally(res => {
      })
    },
    getCateList2(){
      let params = {
        page: 1,
        pageSize: 5,
        newscategoryid: 2
      }
      this.AXIOS.get('/index/getNews', params).then(res => {
          let data = res.data
          console.log(data)
          this.cate7 = data[0] || {}
          this.cate8 = data[1] || {}
          this.cate9 = data[2] || {}
          this.cate10 = data[3] || {}
          this.cate11 = data[4] || {}
      }).finally(res => {
      })
    },
    sendMsg(){
      if(this.msg){
        console.log(this.msg)
        let params = {
          content: this.msg
        }
        this.AXIOS.post('/index/message', params).then(res => {
          this.msgList.push(this.msg)
          this.msg = ''
          this.$Message.warning('消息发送成功')
        }).finally(res => {
        })
      } else {
        this.$Message.warning('请填写消息内容')
      }
    }
  }
}
</script>
<style lang="less">
.index-page {
  min-height: 100%;
  color: #fff;
  padding-bottom: 100px;

  .msg-box {
    display: inline-block;
    width: 450px;
    position: fixed;
    bottom: 50px;
    right: 0;
    z-index: 1000;

    .msg-box-header {
      width: 450px;
      height: 55px;
      line-height: 55px;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: url("../assets/images/msgBoxTitle.png") 100% 100%;
      font-size: 20px;

      .header-left {
        display: flex;
        align-items: center;

        .mail {
          margin-right: 10px;
        }
      }
    }

    .msg-box-body {
      background: #fff;
      min-height: 150px;
      padding: 20px;
      box-sizing: border-box;

      .body-notice {
        background: #f6f6f6;
        border-radius: 10px;
        padding: 20px;
        color: #333;
        border: 1px solid #eee;
        min-height: 100px;
        box-sizing: border-box;
        background: url("../assets/images/msgBoxBg.png") 277px 119px no-repeat;
        background-position: right;
        margin-bottom: 20px;
      }
      
      .msg-list{
        max-height: 300px;
        overflow-y: scroll;
        .msg-item{
          background: #0ecb93;
          border-radius: 10px;
          padding: 10px 20px;
          font-size: 15px;
          border: 1px solid #eee;
          box-sizing: border-box;
          background-position: right;
          margin-bottom: 10px;
          color: #fff;
        }
      }
    }

    .msg-box-footer {
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      border-top: 1px solid #eee;

      .notice-input {
        border: 1px solid #eee;
        background: #f6f6f6;
        padding: 20px;
        width: 100%;
        min-height: 70px;
      }

      .btn-wrap {
        text-align: right;
        padding-top: 10px;
      }

      .send-btn {
        display: inline-block;
        padding: 5px 15px;
        box-sizing: border-box;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        background: #11628D;
      }
    }
  }

  .spe-modal {
    display: inline-block;
    width: 850px;
    z-index: 111;
    height: 866px;
    background: url("../assets/images/modal-bg.png");
    background-size: 850px 866px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    .icon-close {
      font-size: 40px;
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
    }

    &.spe-empty-bg {
      width: 1020px;
      z-index: 111;
      height: 866px;
      background: url("../assets/images/modal-bg-border.png");
      background-size: 1020px 866px;
      padding: 20px 32px;
      .spe-modal-close {
        display: inline-block;
        position: absolute;
        top: 5px;
        right: 5px;
        width: 87px;
        height: 91px;
        cursor: pointer;
      }

      .spe-modal-main {
        box-sizing: border-box;
        height: 700px;
        margin-top: 10px;
      }
    }

    .spe-modal-title {
      font-size: 28px;
      height: 60px;
      padding-left: 10px;
      padding-top: 10px;
      box-sizing: border-box;
    }

    .spe-modal-close {
      display: inline-block;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 87px;
      height: 91px;
      cursor: pointer;
    }

    .spe-modal-main {
      margin-top: 30px;
      box-sizing: border-box;
      height: 590px;
    }

    .modal-empty {
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      height: 730px;
      padding: 20px;
      overflow-y: scroll;
      background: #fff;
      color: #333;
      font-size: 16px;
    }

    .modal-list {
      box-sizing: border-box;
      height: 630px;
      overflow-y: scroll;
      overflow-x: hidden;

      .modal-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        margin-bottom: 15px;
        box-sizing: border-box;
        background: #014761;
        font-size: 20px;
        cursor: pointer;

        &:hover {
          background: darken(#014761, 3%);
        }

        .icon-right {
          font-size: 30px;
        }
      }

    }


    .modal-list-notice {
      text-align: center;
      width: 100%;
      left: 0;
      position: absolute;
      bottom: 0;
      padding: 15px 20px;
      box-sizing: border-box;
      font-size: 14px;
      cursor: pointer;

      .icon-right {
        font-size: 30px;
      }
    }

    .modal-preview {
      .preview-main {
        width: 100%;
        height: 560px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .preview-list {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .preview-bar-out {
          width: 720px;
          overflow-x: hidden;
          overflow-y: hidden;

          .preview-bar {
          }

        }

        .ivu-icon {
          font-size: 30px;
          width: 35px;
          cursor: pointer;
        }

        .preview-item {
          display: inline-block;
          width: 110px;
          height: 110px;
          border-radius: 5px;
          margin-right: 10px;
          border: 3px solid #10a4dc;
          cursor: pointer;
          box-sizing: border-box;
          float: left;

          &.video {
            line-height: 110px;
            text-align: center;
            background: #2baada;

            .ivu-icon {
              font-size: 50px;
              position: relative;
              left: -7px;
              top: 7px;
            }
          }

          &:hover {
            border: 3px solid #fff;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .page-bg-left {
    display: inline-block;
    width: 303px;
    height: 1100px;
    background: url("../assets/images/left.png") 100% 100%;
    position: fixed;
    left: -160px;
  }

  .page-bg-right {
    display: inline-block;
    width: 303px;
    height: 1100px;
    background: url("../assets/images/right.png") 100% 100%;
    position: fixed;
    right: -160px;
  }


  .main-container {
    width: 1480px;
    margin: 0 auto;
    box-sizing: border-box;

    .page-header {
      width: 100%;
      height: 150px;
      box-sizing: border-box;
      display: flex;

      .logo {
        width: 403px;
        height: 150px;
        display: inline-block;
      }

      .top-carousel {
        width: 1070px;

        .img-carousel {
          height: 150px;
          width: 100%;
        }
      }

    }
  }


  .page-main {
    display: flex;
    justify-content: space-between;
    position: relative;

    .panel-round {
      position: absolute;
      width: 100%;
      bottom: 150px;
      .img-round {
        &.round-1 {
          position: absolute;
          left: -20px;
        }

        &.round-2 {
          position: absolute;
          left: 525px;
        }

        &.round-3 {
          position: absolute;
          right: -20px;
        }
      }
    }

    .panel-title {
      display: inline-block;
      width: 335px;
      height: 120px;
      text-align: center;
      font-size: 20px;
      box-sizing: border-box;
      padding-top: 45px;
      background: url("../assets/images/title-bg.png");
      margin: 0 auto;
    }

    .page-left {
      width: 386px;
      text-align: center;

      .left-main {
        display: inline-block;
        width: 386px;
        height: 541px;
        background: url("../assets/images/panel-bg.png") 100% 100%;
        padding-top: 50px;
        padding-left: 20px;
        padding-right: 20px;

        .img-carousel {
          height: 470px;
          width: 100%;
        }
      }
    }

    .page-center {
      flex: 1;
      text-align: center;
      position: relative;

      .square-center {
        display: inline-block;
        width: 267px;
        height: 304px;
        padding-top: 115px;
        background: url("../assets/images/sq-lg.png") 100% 100%;
        position: absolute;
        top: 250px;
        left: 50%;
        transform: translateX(-50%);
        .center-text{
          font-size: 24px;
          padding-bottom: 13px;
        }

        .btn-action {
          cursor: pointer;
          display: inline-block;
          width: 116px;
          height: 39px;
          line-height: 32px;
          text-align: center;
          background: url("../assets/images/btn-more.png") 100% 100%;
        }
      }

      .square-btn {
        position: absolute;

        display: inline-block;
        width: 158px;
        height: 180px;
        text-align: center;
        background: url("../assets/images/sq.png") 100% 100%;
        padding-top: 55px;
        box-sizing: border-box;

        &.square-1 {
          top: 130px;
          left: 135px;
        }

        &.square-2 {
          top: 130px;
          right: 135px;
        }

        &.square-3 {
          top: 310px;
          left: 40px;
        }

        &.square-4 {
          top: 310px;
          right: 40px;
        }

        &.square-5 {
          top: 490px;
          left: 135px;
        }

        &.square-6 {
          top: 490px;
          right: 135px;
        }

        .btn-text {
          font-size: 24px;
          display: block;
          height: 50px;

          &.more-text {
            position: relative;
            top: -20px;
          }
        }

        .btn-action {
          cursor: pointer;
          display: inline-block;
          width: 116px;
          height: 39px;
          line-height: 32px;
          text-align: center;
          background: url("../assets/images/btn-more.png") 100% 100%;
        }
      }

    }

    .page-right {
      width: 386px;
      text-align: center;

      .right-main {
        display: inline-block;
        width: 386px;
        height: 541px;
        background: url("../assets/images/panel-bg.png") 100% 100%;

        .right-list {
          padding-top: 50px;
          box-sizing: border-box;
          position: relative;
          z-index: 111;

          .right-list-item {
            display: inline-block;
            width: 353px;
            height: 76px;
            font-size: 25px;
            padding-top: 25px;
            margin-bottom: 15px;
            box-sizing: border-box;
            cursor: pointer;
            background: url("../assets/images/list-bg.png") 100% 100%;

          }
        }
      }
    }
  }

  .float-btn {
    width: 121px;
    height: 72px;
    box-sizing: border-box;
    cursor: pointer;
    background: url("../assets/images/right-float-bg.png") 100% 100%;
    position: fixed;
    right: 0;
    bottom: 350px;
    border-radius: 5px 0 0 5px;
    overflow: hidden;
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    z-index: 111;
    &.float-btn-2 {
      bottom: 50px;
    }
  }
}
</style>
